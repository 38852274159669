.EmptyScreen {
    padding: 28px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.EmptyScreen__button {
    background: #009ae0;
    border-radius: 8px;
    color: #ffffff;
    text-align: center;
    padding: 12px 10px;
    border: none;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* font-family: circularBook; */
    margin-top: 24px;
}

.EmptyScreen__img {
    width: 120px;

}

.EmptyScreen__para {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    /* font-family: circularBook; */
    color: #000000;
    margin: 0;
    text-align: center;
    justify-content: center;
}

.EmptyScreen__para.para1 {
    margin-top: 24px;
}