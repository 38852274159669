.Awards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.awards-heading {
    background-color: white;
    position: sticky;
    /* border-bottom: 1px solid #E5E5E5; */
    top: 0;
    left: 0;
    padding: 24px 0px 5px 0px;
    width: 100%;
    /* font-family: circularBook; */
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
}