.shimmer-shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right,
            #f6f7f8 0%,
            #edeef1 20%,
            #f6f7f8 40%,
            #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 230px;
    display: inline-block;
    position: relative;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
}
.shimmer-count{
    height: 20px;
    width: 70%;
    margin-bottom: 10px;

}
cashcard {
    height: 230px;
    width: 100%;
}



@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.shimmer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}

.shimmer {
    width: 100%;
    margin: 0;
}

round {
    width: 62px;
    height: 48px;
    border-radius: 50%;
    margin-right: 20px;
}

para {
    height: 48px;
    display: flex;
    width: 100%;
}

.shimmer-heading {
    display: flex;
    padding: 20px 0;
}

.shimmer-buttoncontent{
	display: flex;
	padding: 20px 0;
}
dbutton{
	height: 48px;
	width: 156px;
	margin: 0 10px;
	border-radius: 8px;
}