.container {
  background-color: #0F253F;
  min-height: calc(100vh - 56px);
  margin-top: 56px;

}

#head-banner {

  background-color: #0F253F;
  background-size: cover;
  padding: 22px 16px;
  display: flex;
  justify-content: space-between;
}



#classplus-logo {
  width: 100%;
  margin-left: -2%;
}

#banner-texts {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

#banner-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  /* font-family: circularBold; */
}

#banner-subheading {
  /* font-family: circularLight; */
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #FFFFFF;
  margin-top: 10px;
}

#howToWin-text {
  color: white;
  /* font-family: circularBook; */
  font-style: normal;
  font-weight: 450;
  font-size: 15px;

  margin-bottom: 25%;
  padding-bottom: 5px;
  border-bottom: 1px dashed white;
}

#loading {

  font-size: 1.5rem;
  font-weight: 400;

}


.bottom-sheet {
  height: calc(100vh - 176px);
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 99;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

}

.bottomsheet-data {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  max-width: 800px;

}

@keyframes fadein {
  0% {
    background-color: (0, 0, 0, 0);
  }

  100% {
    background-color: (0, 0, 0, 0.8);
  }
}

@-webkit-keyframes fadein {
  0% {
    background-color: (0, 0, 0, 0);
  }

  100% {
    background-color: (0, 0, 0, 0.8);
  }
}


@-webkit-keyframes slideIn {
  0% {
    bottom: -100vh;
  }

  100% {
    bottom: 0;
  }
}

@keyframes slideIn {
  0% {
    bottom: -100vh;
  }

  100% {
    bottom: 0;
  }
}


.Error {


  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.Error img{
  width: 15%;
  margin-top: 20%;
}