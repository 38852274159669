.Navbar {
    background-color: #009ae0;
    position: fixed;
    top: 0;
    min-height: 56px;
    display: flex;
    padding: 16px 18px;
    width: 100%;
    cursor: pointer;
}

.Navbar__Left {
    display: inherit;
    width: 15%;
    align-items: center;
    justify-content: flex-start;
}

.Navbar__Middle {
    display: inherit;
    width: 85%;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #fff;
    /* font-family: circularBook; */
}